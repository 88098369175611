import { configureStore, combineReducers } from '@reduxjs/toolkit';

import mapSlice from './mapSlice';
import userInputSlice from './userInputSlice';
import datesSlice from './datesSlice';
import authenticationSlice from './authenticationSlice';
import powerBISlice from './powerBISlice';
import selectionLayerSlice from './selectionLayerSlice';
import mapHoverSlice from './mapHoverSlice';
import heatMapSlice from './heatMapSlice';
import quoteSlice from './quoteSlice';

const combinedReducer = combineReducers({
  map: mapSlice,
  userInput: userInputSlice,
  dates: datesSlice,
  authentication: authenticationSlice,
  powerBI: powerBISlice,
  selectionLayer: selectionLayerSlice,
  mapHover: mapHoverSlice,
  heatMap: heatMapSlice,
  quote: quoteSlice,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'authentication/userSignOut') {
    state = undefined;
  } else if (action.type === 'leftPanel/resetAll') {
    state = {
      authentication: state.authentication,
      mapHover: state.mapHover,
      heatMap: state.heatMap,
      selectionLayer: state.selectionLayer,
    }
  }
  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});


export type RootState = ReturnType<typeof store.getState>;

export default store;
