import { NavLink } from 'react-router-dom';
import { powerBIActions } from '../../store/powerBISlice';
import { datesActions } from '../../store/datesSlice';
import { mapActions } from '../../store/mapSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { authenticationActions } from '../../store/authenticationSlice';
import { userInputActions } from '../../store/userInputSlice';
import { navItems } from '../../assets/data/navItems';
import Popup from '../../components/Common/Popup';
import MultiCustomerSelector from '../../pages/MultiCustomers/MultiCustomersSelector';
import IntelematicsLogo from '../../assets/images/logo-cropped.svg';


const NavPanel = () => {
  const dispatch = useDispatch();
  const direction = useSelector ((state) => state.userInput.direction);
  const userEmail = useSelector ((state) => state.authentication.userEmail)
  const userPolicy = useSelector ((state) => state.authentication.userPolicy)
  const selectedCustomer = useSelector ((state) => state.authentication.selectedCustomer)
  const [openPopup, setOpenPopup] = useState(false);
  const [hasOneProduct, setHasOneProduct] = useState(true);

  useEffect(() => {
    let policy = userPolicy?.policy;
    if (policy) {
      if (policy?.length === 1 && policy?.[0]?.customers?.length === 1) {
        setHasOneProduct(true)
      } else {
        setHasOneProduct(false)
      }
    }
  }, [userPolicy]);

  // On SignOut button click dispatch action to the store and change signout state variable to true
  const onClickSignOut = () => {
    dispatch(authenticationActions.userSignOut(true));
  };

  const onClickOutsideLink = (link) => {
    link === 'faq' ?  window.open('https://intelematics.com/insight-decoded', "_blank") :
    window.open('https://intelematics.com/insight-v2-feedback?' + userEmail, '_blank'); 
  }

  const onClickNavItem = (navItem) => {
    dispatch(userInputActions.updateCurrentNavItem(navItem));
    //reset search
    dispatch(mapActions.resetStateForEachState());
    dispatch(userInputActions.updateSearchBoxValue(''));

    switch (navItem) {
      case 'od':
        dispatch(powerBIActions.toggleReportType(direction));
        dispatch(datesActions.updateTimeSelection('monthly'));
        dispatch(userInputActions.updateDisplay('lga'));
        break;
      case 'turning':
        dispatch(powerBIActions.toggleReportType('intersection'));
        dispatch(datesActions.updateTimeSelection('fifteen_min'));
        dispatch(datesActions.removeSecondDateRange());//only one date allowed
        dispatch(userInputActions.updateDisplay('street')) // Select a Display as option. By default LGA ; Street if Intersections (as it's the only possible choice)
        break;
        default:
        dispatch(userInputActions.updateDisplay('lga'));
        dispatch(datesActions.updateTimeSelection('monthly'));
        dispatch(powerBIActions.toggleReportType('compact'));
        break;
    }
  };

  // const setSelectedCustomer = (customer) => {
  //   console.log('NavPanel::selectedCustomer=', customer)
  //   dispatch(authenticationActions.saveSelectedCustomer(customer));
  // }

  // const showMultiCustomerSelectorPopup = () => {

  // }

  return (
    <div className="nav-panel">
      <img src={IntelematicsLogo} alt="" className="logo" />
      
      {
        openPopup && <Popup open={openPopup} setOpen={() => setOpenPopup(false)}>
          <MultiCustomerSelector onSubmit={() => setOpenPopup(false)}/>
        </Popup>
      }

      <div className="nav-panel__container">
        { !hasOneProduct && <div className='menu-item'  onClick={() => setOpenPopup(true)}>{selectedCustomer}</div> }
        <ul>
          {/* {navItems.map((item) => (
            <NavLink
              to={`${item.page}`}
              key={item.page}
              activeClassName="active"
              className={"nav-panel__item " + item.title}
              onClick={() => onClickNavItem(item.title)}
            >
              <li>{item.menuTitle}</li>
            </NavLink>
          ))} */}

          <div className="nav-panel__bottom">       
            {/* <li className="nav-panel__item faq" onClick={() => onClickOutsideLink('faq')}>FAQ</li> */}
            {/* <li className="nav-panel__item feedback" onClick={() => onClickOutsideLink('feedback')}>Give <br/>Feedback</li> */}
            <li className="nav-panel__item logout" onClick={onClickSignOut} aria-hidden="true">
              Logout
            </li>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default NavPanel;
