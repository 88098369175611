import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type SliceState = { signOut: boolean, userEmail: string, userTier: string, selectedCustomer: string, selectedProduct: string, userPolicy: [] };

const INITIAL_STATE: SliceState = {
  signOut: false,
  userEmail: '',
  userTier: '', //V2PAID, V2TRIAL, V2PAYG
  selectedCustomer: '',
  selectedProduct: '',
  userPolicy: []
};

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState: INITIAL_STATE,
  reducers: {
    userSignOut(state, action: PayloadAction<boolean>) {
      state.signOut = action.payload;
    },
    saveUserEmail(state, action: PayloadAction<string>) {
      state.userEmail = action.payload;
    },
    saveUserTier(state, action: PayloadAction<string>) {
      state.userTier = action.payload;
    },
    saveUserPolicy(state, action: PayloadAction<[]>) {
      state.userPolicy = action.payload;
    },
    saveSelectedCustomer(state, action: PayloadAction<string>) {
      state.selectedCustomer = action.payload;
    },
    saveSelectedProduct(state, action: PayloadAction<string>) {
      state.selectedProduct = action.payload;
    },
  },
});

export const authenticationActions = authenticationSlice.actions;
export default authenticationSlice.reducer;
