import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type FeatureDetails = {
  id: string;
  name: string;
};

type HoveredFeatureType = {
  feature: FeatureDetails[];
  pixel: number[];
};

type SliceState = {
  hoveredFeature: HoveredFeatureType;
};

const hoveredFeatureInitState = { feature: [], pixel: [] };

const INITIAL_STATE: SliceState = {
  hoveredFeature: JSON.parse(JSON.stringify(hoveredFeatureInitState)),
};

const mapHoverSlice = createSlice({
  name: 'mapHover',
  initialState: INITIAL_STATE,
  reducers: {
    updateHoveredFeature(state, action: PayloadAction<{ hoveredFeature: HoveredFeatureType }>) {
      state.hoveredFeature = action.payload.hoveredFeature;
    },
    resetState(state) {
      state = INITIAL_STATE;
    },
  },
});

export const mapHoverActions = mapHoverSlice.actions;

export default mapHoverSlice.reducer;
