import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

const FailSafeReport = () => {
  const selectedProduct = useSelector ((state: RootState) => state.authentication.selectedProduct)
  const selectedCustomer = useSelector ((state: RootState) => state.authentication.selectedCustomer)

  const [reportUrl, setReportUrl] = useState("");

  const url = window.location.href;
  let endpoint = "";
  let environment = "";
  if (process.env.NODE_ENV == "development" || url.includes("dev")) {
    endpoint =
      `https://gk8eyvp3hg.execute-api.ap-southeast-2.amazonaws.com/v1`;
    environment = "dev";
  } else if (url.includes("da-stage")) {
    endpoint =
      "https://xzskmsqusj.execute-api.ap-southeast-2.amazonaws.com/v1";
    environment = "da-stage";
  } else if (url.includes("insight.intelematics.com")) {
    endpoint =
      "https://69p9t7yr86.execute-api.ap-southeast-2.amazonaws.com/v1";
    environment = "da-prod";
  }

  // update endpoint based on product and customer
  useEffect(() => {
    if (selectedProduct && selectedCustomer) {
      Auth.currentSession().then((res) => {
        let idToken = res.getIdToken().getJwtToken();
        console.log(`idToken: ${JSON.stringify(idToken)}`);
        setReportUrl(`${endpoint}/${selectedProduct}/index.html?Authorization=${idToken}&Environment=${environment}&Customer=${selectedCustomer}&Product=${selectedProduct}`
        );
      });
    }
  }, [selectedProduct, selectedCustomer])

  return (
    reportUrl &&
      (<div style={{ display: "flex", flexGrow: 1, height: "100vh" }}>
        <iframe
          src={reportUrl}
          title="FailSafe Report"
          style={{ height: "100%", width: "100%" }}
        ></iframe>
      </div>)
  );
};

export default FailSafeReport;
