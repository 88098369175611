import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authenticationActions } from '../../store/authenticationSlice';

const MultiCustomerSelector = ({onSubmit}) => {
  const dispatch = useDispatch();
  const userPolicy = useSelector ((state) => state.authentication.userPolicy)
  const [selectedProduct, setSelectedProduct] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [correspondingCustomerList, setCorrespondingCustomerList] = useState([]);
  const [disableBtnOk, setDisableBtnOk] = useState(true);

  const onProductChange = (evt) => {
    const product = evt.target.value;
    setSelectedProduct(product);
    let customerList = findCorrespondingCustomerList(product)
    setCorrespondingCustomerList(customerList);
    setSelectedCustomer(customerList[0]);
  }

  const onCustomerChange = (evt) => {
    const customer = evt.target.value;
    setSelectedCustomer(customer);
  }

  const findCorrespondingCustomerList = (inputProductName) => {
    let policy = userPolicy?.policy?.find(p => p.product == inputProductName);
    return policy ? policy.customers : [];
  }

  useEffect(() => {
    if (!selectedProduct) {
      const product = userPolicy?.policy?.[0]?.product;
      setSelectedProduct(product);
      setCorrespondingCustomerList(findCorrespondingCustomerList(product));
    }
    if (!selectedCustomer) {
      const customer = userPolicy?.policy?.[0]?.customers?.[0];
      setSelectedCustomer(customer);
    }
  }, [userPolicy]);

  useEffect(() => { 
    if (!selectedProduct && !selectedCustomer) {
      setDisableBtnOk(true)
    }
    if (selectedProduct && selectedCustomer) {
      setDisableBtnOk(false)
    }
  }, [selectedProduct, selectedCustomer]);

  const onButtonClick = () => {
    dispatch(authenticationActions.saveSelectedProduct(selectedProduct));
    dispatch(authenticationActions.saveSelectedCustomer(selectedCustomer));
    console.log('selectedProduct', selectedProduct);
    console.log('selectedCustomer', selectedCustomer);
    onSubmit();
  }

  return (
    <div style={{display: 'flex', fontSize: 'large'}}>
        <div>
            <label>Product</label>
            <select style={{marginLeft: '10px'}} onChange={(e) => onProductChange(e)}>
            {
                userPolicy?.policy?.map(item => {
                return <option key={item?.product} value={item?.product}>{item?.product}</option>
                })
            }
            </select>
        </div>
        <div style={{marginLeft: '30px'}}>
            <label>Customer</label>
            <select style={{marginLeft: '10px'}} onChange={(e) => onCustomerChange(e)}>
            {
                correspondingCustomerList?.map(cus => {
                  return <option key={cus} value={cus}>{cus}</option>
                })
            }
            </select>
        </div>
        <button style={{marginLeft: '20px'}} onClick={() => onButtonClick()} disabled={disableBtnOk}>OK</button>
    </div>
  );
};

export default MultiCustomerSelector;
