import { reportStartDate, reportEndDate } from '../../utils/Date';

export type DateType = {
  dateId: number;
  startDate: string;
  endDate: string;
  time: string[];
  activeDays: string[];
};

export const defaultDate: DateType = {
  dateId: 0,
  // startDate: reportStartDate(),
  // endDate: reportEndDate(), //changed to manual dates for EAP
  startDate: '01/01/2019',
  endDate: '31/03/2022',
  time: ['00:00', '23:59'],
  activeDays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
};
