import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import * as types from '../assets/data/constants';

export type Region = types.GEO_COVERAGE.VIC | types.GEO_COVERAGE.NSW;

export type GeoGranularity = types.GEO_GRANULARITY.LGA | types.GEO_GRANULARITY.SUBURB | types.GEO_GRANULARITY.LINK | types.GEO_GRANULARITY.LINK_TURNING;

export type GeoData = { [key: string]: { on_peak: string; off_peak: string; daily: string } };

export type SliceState = {
  VIC: {
    lga: GeoData;
    suburb: GeoData;
    link: GeoData;
    link_turning: GeoData;
  };
  NSW: {
    lga: GeoData;
    suburb: GeoData;
    link: GeoData;
    link_turning: GeoData;
  };
};

const INITIAL_STATE: SliceState = {
  VIC: {
    lga: {},
    suburb: {},
    link: {},
    link_turning: {},
  },
  NSW: {
    lga: {},
    suburb: {},
    link: {},
    link_turning: {},
  },
};

const heatMapSlice = createSlice({
  name: 'heatMap',
  initialState: INITIAL_STATE,
  reducers: {
    updateHeatMapData(state, action: PayloadAction<{ region: Region; type: GeoGranularity; data: GeoData }>) {
      state[action.payload.region][action.payload.type] = action.payload.data;
    },
    resetState(state) {
      state = INITIAL_STATE;
    },
  },
});

export const heatMapActions = heatMapSlice.actions;

export default heatMapSlice.reducer;
