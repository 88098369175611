import { Route, Switch, Redirect } from 'react-router-dom';
import Dashboard from './pages/Dashboard/Dashboard';
import NotFound from './pages/NotFound/NotFound';
import Accounts from './components/Accounts/Accounts';
import InitialiseAppState from './components/InitialiseAppState/InitialiseAppState';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';

//hotjar.identify('USER_ID', { userProperty: 'value' });

// window.onbeforeunload = function() {
//   return "Refresh";
// }

function App() {
  
  // disabling production logs
  if (process.env.NODE_ENV !== 'development') console.log = () => {};

  return (
     <Accounts>
      <ErrorBoundary>
        {/* <InitialiseAppState /> */}
        <div className="App">
          <Switch>
            <Route path="/" exact>
              <Redirect to="failsafe" />
            </Route>

            <Route path="/">
              <Dashboard />
            </Route>

            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </div>
      </ErrorBoundary>
    </Accounts>
  );
}

export default App;
