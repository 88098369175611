import { createSlice, PayloadAction } from "@reduxjs/toolkit";
type FeatureDetails = {
  id: string;
  name: string;
};
type SliceState = {
  mapDetails: {
    zoom: number;
    latitude: number;
    longitude: number;
    location: string;
    selectedFeatures: FeatureDetails[];
    columnName: string;
    australianState: string;
  };

  cleanSelectandHover: boolean;
  searchPlace: {
    center: number[];
    address: string;
    locationSearch: boolean;
  };
};
const INITIAL_STATE: SliceState = {
  mapDetails: {
    zoom: 10,
    latitude: -37.7692,
    longitude: 144.9606,
    location: "",
    selectedFeatures: [],
    columnName: "",
    australianState: "VIC",
  },

  cleanSelectandHover: false,
  searchPlace: {
    center: [],
    address: "",
    locationSearch: false,
  },
};

type zoomOutCenter = {
  NSW: { latitude: number; longitude: number };
  VIC: { latitude: number; longitude: number };
};
const zoomOutCenter = {
  NSW: { latitude: -33.8223, longitude: 150.9921 },
  VIC: { latitude: -37.7692, longitude: 144.9606 },
};

const mapSlice = createSlice({
  name: "map",
  initialState: INITIAL_STATE,
  reducers: {
    updateZoom(state, action: PayloadAction<number>) {
      state.mapDetails.zoom = action.payload;
    },
    updateCoordinates(state, action: PayloadAction<number[]>) {
      state.mapDetails.latitude = action.payload[0];
      state.mapDetails.longitude = action.payload[1];
    },
    updateLocation(state, action: PayloadAction<string>) {
      state.mapDetails.location = action.payload;
    },
    updateAustralianState(state, action: PayloadAction<string>) {
      state.mapDetails.australianState = action.payload;
    },
    updateFeatureDetails(
      state,
      action: PayloadAction<{
        selectedFeatures: FeatureDetails[];
        columnName: string;
      }>
    ) {
      state.mapDetails.selectedFeatures = action.payload.selectedFeatures;
      state.mapDetails.columnName = action.payload.columnName;
    },
    resetStateForEachState(state) {
      if (state.mapDetails.australianState === "NSW") {
        state.mapDetails = {
          ...INITIAL_STATE.mapDetails,
          australianState: state.mapDetails.australianState,
          location: state.mapDetails.location,
          zoom: 10,
          latitude: zoomOutCenter["NSW"]["latitude"],
          longitude: zoomOutCenter["NSW"]["longitude"],
        };
      } else if (state.mapDetails.australianState === "VIC") {
        state.mapDetails = {
          ...INITIAL_STATE.mapDetails,
          australianState: state.mapDetails.australianState,
          location: state.mapDetails.location,
          zoom: 10,
          latitude: zoomOutCenter["VIC"]["latitude"],
          longitude: zoomOutCenter["VIC"]["longitude"],
        };
      }
    },
    isLocationSearch(state, action) {
      state.searchPlace.locationSearch = action.payload;
    },
    resetCleanSelectandHover(state, action) {
      state.cleanSelectandHover = !state.cleanSelectandHover;
    },
    updateSearchPlace(
      state,
      action: PayloadAction<{ center: number[]; address: string }>
    ) {
      state.searchPlace.center = action.payload.center;
      state.searchPlace.address = action.payload.address;
    },
  },
});
export const mapActions = mapSlice.actions;
export default mapSlice.reducer;
