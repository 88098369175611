export const isProduction = process.env.NODE_ENV === 'production';
export const isDevelopment = process.env.NODE_ENV === 'development';

export enum AU_STATE {
  NSW = 'New South Wales',
  VIC = 'Victoria',
}

export enum GEO_IDENTIFIER {
  LGA = 'LGA_NAME16',
  SUBURB = 'SSC_NAME16',
  STREET = 'entity_intelemap_link_id',
}

export enum GEO_COVERAGE {
  NSW = 'NSW',
  VIC = 'VIC',
}

export enum GEO_GRANULARITY {
  LGA = 'lga',
  SUBURB = 'suburb',
  STREET = 'street',
  ROAD = 'road',
  LINK = 'link',
  LINK_TURNING = 'link_turning',
}

export enum TIME_GRANULARITY {
  FIFTEEN_MIN = 'fifteen_min',
  HOURLY = 'hourly',
  DAILY = 'daily',
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}

export enum PEAK_PERIOD {
  DAILY = 'daily',
  ON_PEAK = 'on_peak',
  OFF_PEAK = 'off_peak',
}

export enum MAP_EVENT {
  CLICK = 'click',
  POLYEND = 'polyend',
  POINTERMOVE = 'pointermove',
}

export enum REPORT_TYPE {
  COMPACT = 'compact',
  EXPANDED = 'expanded',
  COMPARE = 'compare',
  DOWNLOAD = 'download',
  ORIGIN = 'origin',
  DESTINATION = 'destination',
  INTERSECTION = 'intersection',
}
