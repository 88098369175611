import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { reportTokenKey } from '../utils';
import * as types from '../assets/data/constants';

type TokenKey = {
  reportType: string; // compact | expanded | intersection | download
  dataSet: string; // VOLUME
  geoCoverage: string; // NSW | VIC
  geoGranularity: string; // LGA | Suburb
  timeGranularity: string; // Monthly
};

type TokenInfo = {
  access_token: string;
  embed_url: string;
  token_expiry: string;
  token_iat: string;
};

type PBIToken = {
  [key: string]: TokenInfo;
};

type AddNewToken = {
  tokenKeyInfo: TokenKey;
  tokenInfo: TokenInfo;
};

type SliceState = {
  isCollapsed: boolean;
  hasRendered: boolean;
  reportType: types.REPORT_TYPE;
  pbiTokens: PBIToken[];
  updatedNotification: boolean;
};

const INITIAL_STATE: SliceState = {
  isCollapsed: false,
  hasRendered: false,
  reportType: types.REPORT_TYPE.COMPACT,
  pbiTokens: [],
  updatedNotification: false,
};

const powerBISlice = createSlice({
  name: 'powerBI',
  initialState: INITIAL_STATE,
  reducers: {
    toggleIsCollapsed(state, action: PayloadAction<boolean>) {
      state.isCollapsed = action.payload;
    },
    toggleReportType(state, action: PayloadAction<types.REPORT_TYPE>) {
      state.reportType = action.payload;
    },
    toggleHasRendered(state, action: PayloadAction<boolean>) {
      state.hasRendered = action.payload;
    },
    addPBIToken(state, action: PayloadAction<AddNewToken>) {
      const { reportType, dataSet, geoCoverage, geoGranularity, timeGranularity } = action.payload.tokenKeyInfo;
      const tokenKey: any = reportTokenKey(reportType, dataSet, geoCoverage, timeGranularity, geoGranularity);
      let tokenObj: PBIToken = {};
      tokenObj[tokenKey] = action.payload.tokenInfo;
      state.pbiTokens.push(tokenObj);
    },
    toggleUpdatedNotification(state, action) {
      state.updatedNotification = action.payload;
    },
  },
});

export const powerBIActions = powerBISlice.actions;
export default powerBISlice.reducer;
