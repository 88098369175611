export const getUser = /* GraphQL */ `
  query GetUser($user_id: String!) {
    getUser(user_id: $user_id) {
      user_id
      policy {
        customers
        product
      }
    }
  }
`;
