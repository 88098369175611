import React, { ReactNode } from "react";

type Props = {
    open: Boolean,
    setOpen: (isOpen: Boolean) => void,
    children: ReactNode
}

const Popup = (props: Props) => {
    return props.open && (
      <div className="popup-container">
        
        <div className="popup-body">
          <button style={{float: 'right', backgroundColor: 'red'}} onClick={() => props.setOpen(false)}>X</button>
          <div className="popup-content">
            {props.children}
          </div>
        </div>
      </div>
    );
};

export default Popup;