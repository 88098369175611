import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type CartDetails = {
    product: string;
    location: string[];
    state: string;
    type: string;
    dateRange: string;
    time: string;
};

type SlackDetails = {
    date: number,
    LGA: string[],
    suburb: string[],
    timeRangeStart: string,
    timeRangeEnd: string,
    linksIds: string[],
    State: string,
    product: string,
    days: string[],
}   

type SliceState = {
  cart: CartDetails[];
  slackData: SlackDetails[];
};


const INITIAL_STATE: SliceState = {
  cart: [],
  slackData: [],
};

const quoteSlice = createSlice({
  name: 'quote',
  initialState: INITIAL_STATE,
  reducers: {
    updateCart(state, action) {
      state.cart = action.payload;
    },
    updateSlackData(state, action) {
      state.slackData = action.payload;
    }
  },
});

export const quoteActions = quoteSlice.actions;
export default quoteSlice.reducer;
