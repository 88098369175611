import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import * as types from '../assets/data/constants';
import { defaultDate, DateType } from '../assets/data/dates';

type PeakPeriod = types.PEAK_PERIOD.DAILY | types.PEAK_PERIOD.OFF_PEAK | types.PEAK_PERIOD.ON_PEAK

type SliceState = {
  dateRange: DateType[];
  tempDateRange: DateType[];
  timeSelection: types.TIME_GRANULARITY;
  peakPeriod: PeakPeriod;
};

const INITIAL_STATE: SliceState = {
  dateRange: [{ ...defaultDate }],
  tempDateRange: [{ ...defaultDate }],
  timeSelection: types.TIME_GRANULARITY.MONTHLY,
  peakPeriod: types.PEAK_PERIOD.DAILY
};

const datesSlice = createSlice({
  name: 'dates',
  initialState: INITIAL_STATE,
  reducers: {
    copyTempToGlobal(state, action: PayloadAction<number>) {
      state.dateRange[action.payload] = state.tempDateRange[action.payload];
    },
    copyGlobalToTemp(state) {
      state.tempDateRange = state.dateRange;
    },
    updateTime(state, action) {
      state.tempDateRange[action.payload[1]].time = action.payload[0];
    },
    updateStartDate(state, action) {
      state.tempDateRange[action.payload[1]].startDate = action.payload[0];
    },
    updateEndDate(state, action) {
      state.tempDateRange[action.payload[1]].endDate = action.payload[0];
    },
    updateActiveDays(state, action) {
      state.tempDateRange[action.payload[1]].activeDays = action.payload[0];
    },
    addSecondDateRange(state) {
      state.dateRange = [
        ...state.dateRange,
        {
          ...defaultDate,
          dateId: 1,
        },
      ];
      state.tempDateRange = [
        ...state.tempDateRange,
        {
          ...defaultDate,
          dateId: 1,
        },
      ];
    },
    removeSecondDateRange(state) {
      state.dateRange = [...state.dateRange.slice(0, 1), ...state.dateRange.slice(2)];
      state.tempDateRange = [...state.tempDateRange.slice(0, 1), ...state.tempDateRange.slice(2)];
    },
    resetState(state) {
      state.dateRange = INITIAL_STATE.dateRange;
      state.tempDateRange = INITIAL_STATE.tempDateRange;
    },
    resetTempDateIdState(state, action) {
      state.tempDateRange[action.payload] = { ...defaultDate, dateId: action.payload };
    },

    updateTimeSelection(state, action) {
      state.timeSelection = action.payload;
    },
    updatePeakPeriod(state, action) {
      state.peakPeriod = action.payload;
    },
    swapDates(state) {
      const dateCopy = state.dateRange[0];
      state.dateRange[0] = {...state.dateRange[1], dateId: 0};
      state.dateRange[1] = {...dateCopy, dateId: 1};

      state.tempDateRange[0] = {...state.tempDateRange[1], dateId: 0};
      state.tempDateRange[1] = {...dateCopy, dateId: 1};
    }
  },
});

export const datesActions = datesSlice.actions;
export default datesSlice.reducer;
