import NavPanel from '../../components/NavPanel/NavPanel';
// import UserInputPanel from '../../components/UserInputPanel/UserInputPanel';
// import MapContainer from '../../components/Map/MapContainer';
// import PowerBIContainer from '../../components/PowerBI/PowerBIContainer';
// import Legend from '../../components/Legend/Legend';
import FailSafeReport from '../../components/FailSafeReport/FailSafeReport';
import Popup from '../../components/Common/Popup';
import MultiCustomerSelector from '../MultiCustomers/MultiCustomersSelector';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authenticationActions } from '../../store/authenticationSlice';

const Dashboard = () => {
  const dispatch = useDispatch();
  const [openPopup, setOpenPopup] = useState(false);
  const userPolicy = useSelector ((state) => state.authentication.userPolicy)

  useEffect(() => {
    let policy = userPolicy?.policy;
    if (policy) {
      if (policy?.length === 1 && policy?.[0]?.customers?.length === 1) {
        dispatch(authenticationActions.saveSelectedProduct(policy[0].product));
        dispatch(authenticationActions.saveSelectedCustomer(policy[0].customers[0]));
        setOpenPopup(false)
      } else {
        setOpenPopup(true)
      }
    }
  }, [userPolicy]);

  return (
    <div className="main">
      { 
        openPopup ? <Popup open={openPopup} setOpen={() => setOpenPopup(false)}>
          <MultiCustomerSelector onSubmit={() => setOpenPopup(false)}/>
        </Popup> :
        <>
          <NavPanel/>
          <FailSafeReport/>
        </>
      }
      

        {/* <> 
          <UserInputPanel />
          <MapContainer />
          <Legend />
          <PowerBIContainer />
        </> */}
    </div>
  );
};

export default Dashboard;
