import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import * as types from '../assets/data/constants';

export type StateGeoData = {
  Victoria: string[];
  New_South_Wales: string[];
};

type IndividualState = {
  type: string;
  data: StateGeoData;
};

type SliceState = {
  lgaGeoData: StateGeoData;
  suburbGeoData: StateGeoData;
};

const INITIAL_STATE: SliceState = {
  lgaGeoData: {
    Victoria: [],
    New_South_Wales: [],
  },
  suburbGeoData: {
    Victoria: [],
    New_South_Wales: [],
  },
};

const selectionLayerSlice = createSlice({
  name: 'selectionLayer',
  initialState: INITIAL_STATE,
  reducers: {
    updateGeoData(state, action: PayloadAction<IndividualState>) {
      if (action.payload.type == types.GEO_GRANULARITY.LGA) {
        state.lgaGeoData = action.payload.data;
      } else if (action.payload.type == types.GEO_GRANULARITY.SUBURB) {
        state.suburbGeoData = action.payload.data;
      }
    },
    updateLGAGeoData(state, action: PayloadAction<StateGeoData>) {
      state.lgaGeoData = action.payload;
    },
    updateSuburbGeoData(state, action: PayloadAction<StateGeoData>) {
      state.suburbGeoData = action.payload;
    },
    resetState(state) {
      state.lgaGeoData = INITIAL_STATE.lgaGeoData;
      state.suburbGeoData = INITIAL_STATE.suburbGeoData;
    },
  },
});

export const selectionLayerActions = selectionLayerSlice.actions;

export default selectionLayerSlice.reducer;
