export const reportTokenKey = (
  reportType: string,
  dataSet: string,
  geoCoverage: string,
  timeGranularity: string,
  geoGranularity: string,
): string => {
  const tokenKey: any = `${reportType.toLowerCase()}_${dataSet.toLowerCase()}_${geoCoverage.toLowerCase()}_${timeGranularity.toLowerCase()}_${geoGranularity.toLowerCase()}_${process.env.REACT_APP_REPORT_VERSION!.toLowerCase()}`;
  console.log('powerbi tokenkey: '+ tokenKey)
  return tokenKey;
};
