/**
 * Amplify UI Component
 * Invokes Amplify UI Component library to render sign-in and forgot password components
 *
 * Ref UI Components: https://github.com/aws-amplify/amplify-js/tree/main/packages/amplify-ui-components/src/components
 * Ref Sign-In: https://github.com/aws-amplify/amplify-js/tree/main/packages/amplify-ui-components/src/components/amplify-sign-in
 * Ref Forgot-Password: https://github.com/aws-amplify/amplify-js/tree/main/packages/amplify-ui-components/src/components/amplify-forgot-password
 */

import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Amplify, { Auth, API, graphqlOperation } from 'aws-amplify';
import {
  AmplifyAuthenticator,
  // AmplifySignUp,
  AmplifySignIn,
  AmplifyContainer,
  AmplifyForgotPassword,
} from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { getUser } from '../../graphql/User/queries';

//import awsconfigs from '../../aws-export';
var awsconfigs = require('../../aws-export.json');
import { authenticationActions } from '../../store/authenticationSlice';
import IntelematicsLogo from '../../assets/images/logo-intelematics.svg';

// changing the config depending on url and env
let awsconfig = awsconfigs['env']['dev']; // defaults to dev on localhost, https://insight.dev.dac.cloud.intelematics.com/
if (typeof window !== 'undefined') {
  if(location.host.includes("insight.intelematics")) { // prod
    console.log("We're in Prod url");  // EH: TODO - remove on release
    awsconfig = awsconfigs['env']['da-prod'];

  } else if(location.host.includes("insight.da-stage")) { // stage
    console.log("We're in Stage url");  // EH: TODO - remove on release
    awsconfig = awsconfigs['env']['da-stage'];

  }
}
Amplify.configure(awsconfig);

const AuthStateApp = ({ children }) => {
  const dispatch = useDispatch();

  const [authState, setAuthState] = useState();
  const [user, setUser] = useState();
  const signOutState = useSelector((state) => state.authentication.signOut);

  // eslint-disable-next-line no-console
  console.log('Auth State 1: ', authState, signOutState);

  // Setting auth and user state on first login
  useEffect(
    () =>
      onAuthUIStateChange((nextAuthState, authData) => {
        setAuthState(nextAuthState);
        setUser(authData);

        if (authData) {
          dispatch(authenticationActions.saveUserEmail(authData.attributes.email));
        }
        // console.log("user data: ", authData);
      }),
    [],
  );

  // fetch user tier using appsync
  const getUserTier = async (userID) => {
    try {
        const response = await API.graphql(
            graphqlOperation(getUser, {
                user_id: userID
            }),
        );

        if (response.data.getUser) {
            const userPolicy = response.data.getUser
            if (userPolicy) {
              dispatch(authenticationActions.saveUserPolicy(userPolicy));
            }
        }
    } catch (e) {
        console.log("fetch user tier error:", e);
    }
}
  // get user tier 
  useEffect(()=>{
    if (user){
      const userID = user.username
      getUserTier(userID)
      
    }
  } ,[user])

  // Signout from the app when the signOut state (authenticationSlice) variable changes to true
  useEffect(() => {
    if (signOutState === true) {
      const signOutHandler = async () => {
        try {
          await Auth.signOut();
          setAuthState(AuthState.SignOut);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log('error signing out: ', error);
        }
      };
      signOutHandler();
    }
  }, [signOutState]);

  // Reset the signout state variable to false (default)
  // post signout by sending a dispatch action to the store
  useEffect(() => {
    if (authState === AuthState.SignIn && signOutState === true) {
      dispatch(authenticationActions.userSignOut(false));
    }
  }, [authState, signOutState, dispatch]);

  // const signUpComponent = (
  //   <AmplifySignUp
  //     slot="sign-up"
  //     usernameAlias="email"
  //     formFields={[
  //       {
  //         type: 'email',
  //         label: 'email Label',
  //         placeholder: 'email placeholder',
  //         required: true,
  //       },
  //       {
  //         type: 'password',
  //         label: 'Password Label',
  //         placeholder: 'password placeholder',
  //         required: true,
  //       },
  //       {
  //         type: 'phone_number',
  //         label: 'Phone Label',
  //         placeholder: 'Phone placeholder',
  //         required: false,
  //       },
  //     ]}
  //   />
  // );

  const intelematicsHeader = (
    <div slot="intelematics-header" className="intelematics-header">
      <img style={{ width: '288px' }} src={IntelematicsLogo} alt="Intelematics Logo" />
    </div>
  );

  const signInComponent = (
    // https://github.com/aws-amplify/amplify-js/issues/5298
    <AmplifySignIn
      slot="sign-in"
      formFields={[
        { type: 'email', label: 'Email address', placeholder: '', required: true },
        {
          type: 'password',
          label: 'Password',
          placeholder: '',
          required: true,
          // hint: '',
        },
      ]}
      usernameAlias="email"
      hideSignUp="true"
      headerText="Login"
      submitButtonText="Login"
    />
  );

  const forgotPasswordComponent = (
    <AmplifyForgotPassword
      // headerText="Enter email address"
      // sendButtonText="CONFIRM"
      usernameAlias="email"
      slot="forgot-password"
      formFields={[
        {
          type: 'email',
          label: 'Email address',
          required: true
        },
      ]}
    />
  );

  const amplifyLoginComponent = (
    <AmplifyContainer id="amplify-container">
      {intelematicsHeader}
      <AmplifyAuthenticator usernameAlias="email">
        {signInComponent}
        {forgotPasswordComponent}
        {/* {signUpComponent} */}
        {/* <AmplifySelectMfaType MFATypes={MFATypeOptions}></AmplifySelectMfaType> */}
      </AmplifyAuthenticator>
    </AmplifyContainer>
  );

  return (
    <>
      {/* routing for reset password */}
      {authState === AuthState.ForgotPassword ? <Redirect to="/resetpassword" /> : null}

      {/* routing for login */}
      {authState === AuthState.SignIn ? <Redirect to="/login" /> : null}

      {/* rendering app component */}
      {authState === AuthState.SignedIn && user ? (
        <>
          <Redirect to="/failsafe" />
          <div className="amplify-app">
            {/* <AmplifySignOut /> */}
            {children}
          </div>
        </>
      ) : (
        amplifyLoginComponent
      )}
    </>
  );
};

export default AuthStateApp;
