import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type SliceState = {
  geoDisplayType: string;
  geoStreets: string[];
  direction: string;
  dateRangePanel: string;
  currentNavItem: string;
  searchBoxValue: string;
  resetAll: boolean;
};

const INITIAL_STATE: SliceState = {
  geoDisplayType: 'lga',//lga, suburb, street
  geoStreets: ['highways', 'motorways', 'main-roads', 'roads'],
  direction: 'Origin',
  dateRangePanel: '',
  currentNavItem: 'volume',// Volume/speed/od/intersection
  searchBoxValue: '',
  resetAll: false,
};

const userInputSlice = createSlice({
  name: 'leftPanel',
  initialState: INITIAL_STATE,
  reducers: {
    updateDisplay(state, action: PayloadAction<string>) {
      state.geoDisplayType = action.payload;
    },
    updateGeoStreets(state, action: PayloadAction<string[]>) {
      state.geoStreets = state.geoStreets === action.payload ? [] : action.payload;
    },
    updateDirection(state, action: PayloadAction<string>) {
      state.direction = action.payload;
    },
    toggleLeftExtension(state, action: PayloadAction<string>) {
      state.dateRangePanel = action.payload;
    },
    updateCurrentNavItem(state, action: PayloadAction<string>) {
      state.currentNavItem = action.payload;
    },
    updateSearchBoxValue(state, action: PayloadAction<string>) {
      state.searchBoxValue = action.payload;
    },
    resetState(state) {
      state.geoDisplayType = INITIAL_STATE.geoDisplayType;
      state.dateRangePanel = INITIAL_STATE.dateRangePanel;
    },
    resetAll(state) {
      state.resetAll = true
    }
  },
});

export const userInputActions = userInputSlice.actions;
export default userInputSlice.reducer;
